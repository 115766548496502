<template>
  <div>
    <b-button
      type="submit"
      style="border-radius:11px"
      variant="primary"
      id="show-btn"
      @click="$bvModal.show('create-team-modal')"
    >
      <span>{{ $t("teams.create_team") }}</span>
    </b-button>

    <b-modal id="create-team-modal" centered hide-footer hide-header size="lg">
      <b-overlay :show="loading">
        <b-container fluid class="p-4">
          <!-- Header Section -->
          <div class="d-flex align-items-center  justify-content-between">
            <h1>{{ $t("teams.create_new_team") }}</h1>
            <p
              @click="$bvModal.hide('create-team-modal')"
              style="cursor:pointer; color:#545454"
            >
              {{ $t("cancel") }}
            </p>
          </div>

          <div class="mt-4">
            <b-form @submit.prevent="addTeam">
              <b-row cols="1" align-v="center" class="">
                <b-col cols="8" v-if="hierarchyOrganizations.length">
                  <b-form-group
                    :label="`${$t('teams.choose_organization')}:`"
                    label-for="chooseOrganization"
                    class="text-terciary mb-4 font-weight-bold"
                  >
                    <b-form-select
                      v-model="selectOrganizationId"
                      id="chooseOrganization"
                      class="mb-3"
                    >
                      <b-form-select-option
                        v-for="(organization, index) in hierarchyOrganizations"
                        :value="organization.id"
                        :key="index"
                      >
                        {{ organization.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col cols="8">
                  <b-form-group
                    :label="`${$t('teams.team_name')}:`"
                    label-for="teamName"
                    class="text-terciary mb-4 font-weight-bold"
                  >
                    <b-form-input
                      id="teamName"
                      name="teamName"
                      v-model="teamName"
                      type="text"
                      required
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    :label="`${$t('teams.choose_avatar')}:`"
                    v-slot="{ ariaDescribedby }"
                    class="text-terciary font-weight-bold"
                  >
                    <b-form-radio-group
                      id="team-avatar-radio-group"
                      v-model="selectTeamAvatarId"
                      :aria-describedby="ariaDescribedby"
                      name="team-avatar"
                      class="d-flex"
                    >
                      <b-row class="w-100">
                        <div v-for="image in teamAvatars" :key="image.id">
                          <b-form-radio
                            type="radio"
                            :value="image.id"
                            class="d-flex"
                          >
                            <b-img
                              class="rounded-circle mb-3"
                              style="object-fit: cover;"
                              height="50"
                              width="50"
                              center
                              :src="image.file_path + image.file_name"
                              alt=""
                            />
                          </b-form-radio>
                        </div>
                      </b-row>
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>

                <b-col class="mt-3">
                  <b-button
                    block
                    variant="primary"
                    type="submit"
                    :disabled="!isValid"
                    v-if="!spinnerLoading"
                  >
                    {{ $t("teams.create_team") }}
                  </b-button>
                  <b-button
                    block
                    variant="primary"
                    disabled
                    v-if="spinnerLoading"
                  >
                    <b-spinner small></b-spinner>
                    <span class="sr-only">Loading...</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-container>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "CreateTeamModal",

  data() {
    return {
      loading: false,
      spinnerLoading: false,
      teamName: "",
      selectOrganizationId: "",
      selectTeamAvatarId: null,

      hierarchyOrganizations: [],
      teamAvatars: [],
    }
  },

  computed: {
    isValid() {
      return this.teamName && this.selectTeamAvatarId
    },
  },

  methods: {
    async addTeam() {
      this.spinnerLoading = true

      try {
        let data = {
          team_name: this.teamName,
          team_avatar_id: this.selectTeamAvatarId,
        }

        if (this.selectOrganizationId) {
          data["hiearchy_org_id"] = this.selectOrganizationId
        }

        const response = await axios.post("/team/create", data)

        if (response.status == 200) {
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: this.$t("teams.team_created"),
          })

          // Close Modal
          this.$bvModal.hide("create-team-modal")
          this.$emit("getAllTeam")
          this.$emit("getOrgTeam")
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.spinnerLoading = false
      }
    },

    // To Clear the Input when Modal Closes
    clearInput() {
      this.selectOrganizationId = ""
      this.selectTeamAvatarId = null
      this.teamName = ""
    },

    async getTeamHierarchy() {
      this.loading = true

      try {
        const response = await axios.get("/team/hierarchy")
        this.hierarchyOrganizations = response.data?.data ?? []
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getTeamAvatar() {
      try {
        const response = await axios.get("/team/avatars")

        this.teamAvatars = response.data?.data ?? []
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },
  },
  created() {
    this.getTeamHierarchy()
    this.getTeamAvatar()
  },
  mounted() {
    this.$root.$on("bv::modal::show", () => {
      this.clearInput()
    })
  },
}
</script>

<style scoped>
#team-avatar-radio-group >>> .custom-control-label::before {
  opacity: 0;
}
#team-avatar-radio-group >>> .custom-control-label img {
  cursor: pointer;
}
#team-avatar-radio-group
  >>> .custom-control-input:checked
  + .custom-control-label
  img {
  outline: 2px solid #E63375;
}
</style>
