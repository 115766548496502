<template>
  <b-card no-body class="py-4 h-100 d-flex flex-column">
    <!-- Top Section -->
    <div class="text-center">
      <b-img
        class="rounded-circle mb-3"
        style="object-fit: cover;"
        height="68"
        width="68"
        center
        :src="`${team.media_file.file_path}${team.media_file.file_name}`"
        alt=""
      />
      <div class="d-flex justify-content-center">
        <b class="text-terciary">{{ team.name }}</b>
      </div>
      <hr class="mx-3" />
    </div>

    <!-- Team Members Section -->
    <div class="">
      <b-row class="m-0 p-0 mb-2">
        <b-col
          lg="6"
          md="6"
          sm="6"
          cols="6"
          v-for="member in team.campaign_group_user"
          :key="member.id"
        >
          <p class="m-0 mb-1 pl-2 text-terciary size">
            {{ member.user_profile.full_name }}
          </p>
        </b-col>
      </b-row>
    </div>

    <div class="mx-auto mt-auto px-3 ">
      <b-button
        pill
        variant="primary"
        class="py-2 px-5"
        type="link"
        size="sm"
        :to="{ path: '/team/' + team.id }"
      >
        <span class="size">{{ $t("teams.visit_team") }}</span>
      </b-button>
    </div>

    <!-- Add to Gameboard Checkbox -->
    <b-row class="mt-3 px-3" no-gutters>
      <b-col cols="12" class="d-flex justify-content-center align-items-center">
        <span class="text-terciary size font-weight-semibold">
          {{ $t("teams.show_on_gameboard") }}
        </span>
        <div>
          <b-form-checkbox
            v-if="team.my_team === 'Y'"
            v-model="myTeamChecked"
            name="check-button"
            switch
            @change="checkOwnTeam($event)"
            size="md"
            class="checkbox-input  ml-3 "
          >
          </b-form-checkbox>

          <div v-else>
            <b-form-checkbox
              v-if="team.campaign_group_user.length"
              v-model="AddToGameBoardChecked"
              @change="check($event)"
              name="check-button"
              switch
              class="checkbox-input  ml-3 "
            >
            </b-form-checkbox>
            <b-form-checkbox
              v-else
              name="check-button"
              switch
              disabled
              class="checkbox-input  ml-3 "
            >
            </b-form-checkbox>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from "axios"
export default {
  props: ["team"],
  data() {
    return {
      myTeamChecked: true,
      AddToGameBoardChecked: null,
    }
  },
  methods: {
    async check(e) {
      try {
        let data = {
          team_id: this.team.id,
        }
        if (e) {
          const response = await axios.post("/gameboard/add", data)
          if (response.status === 200) {
            this.$store.dispatch("addToastMessage", {
              type: "success",
              text: this.$t("teams.team_added"),
            })
          }
        } else {
          const response = await axios.post("/gameboard/add", data)
          if (response.status === 200) {
            this.$store.dispatch("addToastMessage", {
              type: "success",
              text: this.$t("teams.team_removed"),
            })
          }
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },
    checkOwnTeam(e) {
      if (!e) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: this.$t("teams.cannot_remove_own"),
        })
        this.myTeamChecked = true
      }
    },
  },
  mounted() {
    if (this.team.added_on_gameboard === "Y") {
      this.AddToGameBoardChecked = true
    }
  },
}
</script>

<style scoped>
.container {
  display: flex;
  border: 1px solid;
}
.colss {
  margin: 10px;
  border: 1px solid;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.item-container {
  border: 1px solid;
  padding: 5px;
  margin: 5px;
}
.size {
  font-size: 12px;
}

@media (min-width: 800px) {
  .size {
    font-size: 14px;
  }
}
@media (min-width: 1200px) {
  .size {
    font-size: 15px;
  }
}
</style>
