<template>
  <b-container class="my-2 my-sm-5 team" fluid>
    <b-row align-v="start">
      <b-col lg="2" md="12" class="">
        <h1>{{ $t("teams.pagename") }}</h1>
      </b-col>

      <b-col lg="10" md="12" class="">
        <b-row no-gutters align-v="start">
          <b-col lg="5" md="12" class="input-field">
            <b-form-group class="with-icon mr-3">
              <b-icon-search />
              <b-form-input
                id="search"
                name="search"
                size="sm"
                class="py-4"
                @keyup.enter="searchTeamRequest()"
                v-model="searchQuery"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col lg="2" md="12">
            <b-button
              variant="primary"
              type="submit"
              class=" py-2 mt-1 px-3 "
              size="sm"
              @click="searchTeamRequest"
            >
              {{ $t("search") }}
            </b-button>
          </b-col>
          <!-- </div> -->

          <b-col lg="5" md="12">
            <div id="create-team">
              <CreateTeamModal
                @getAllTeam="getAllTeam()"
                @getOrgTeam="getMyOrgTeam()"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col cols="12">
        <b-row no-gutters>
          <b-col cols="12" class="d-flex justify-content-end pr-3">
            <span class="text-terciary mr-3">
              {{ $t("dashboard.teams_only_my_organization") }}
            </span>
            <b-form-checkbox
              v-model="TeamChecked"
              name="check-button"
              switch
              class="checkbox-input"
            >
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-overlay :show="loading">
          <b-row class="mt-5  ">
            <b-col
              xl="3"
              lg="4"
              md="6"
              sm="12"
              v-for="team in searchTeams"
              :key="team.id"
              class="mb-5"
            >
              <TeamCard :team="team" />
            </b-col>

            <!-- Functions trigger when user reaches bottom of the div -->
            <div v-if="searchTeams.length">
              <div
                v-if="TeamChecked"
                v-observe-visibility="MyOrgScrolledToBottom"
              ></div>
              <div v-else v-observe-visibility="ScrolledToBottom"></div>
            </div>
          </b-row>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios"

import CreateTeamModal from "@/components/CreateTeamModal.vue"
import TeamCard from "@/components/TeamCard.vue"
import { BIconSearch } from "bootstrap-vue"
export default {
  components: { BIconSearch, CreateTeamModal, TeamCard },
  data() {
    const team_checked_storage = localStorage.getItem("team_checked")
    return {
      loading: false,
      searchedTeamRequest: [],
      searchQuery: "",
      error: "",
      allTeams: [],
      links: null,
      myOrgTeams: [],
      myOrgTeamsLink: null,
      TeamChecked: team_checked_storage
        ? team_checked_storage === "true" // string to boolean
        : true, // fallback to true
    }
  },
  computed: {
    searchTeams() {
      if (this.TeamChecked && !this.searchedTeamRequest.length)
        return this.myOrgTeams
      if (this.searchedTeamRequest.length) return this.searchedTeamRequest

      return this.allTeams
    },
    myTeam() {
      return this.allTeams.filter(team => {
        return team.my_team === "Y"
      })
    },
  },
  watch: {
    searchQuery() {
      if (this.searchedTeamRequest.length && this.searchQuery === "") {
        this.searchedTeamRequest = []
      }
    },
    TeamChecked(val) {
      localStorage.setItem("team_checked", val)
    },
  },

  methods: {
    async getAllTeam(pageURL, isPaginated) {
      this.loading = true
      let url = `/team`
      if (pageURL) {
        url = new URL(pageURL)
        url = url.toString().substring(url.origin.length)
        url = url.replace(/^(\/[\w-]+)*\/api\//, "/") // replace possible duplicate api path
      }
      try {
        const response = await axios.get(url)
        if (isPaginated) {
          this.allTeams = [...this.allTeams, ...(response.data?.data ?? [])]
        } else {
          this.allTeams = response.data?.data ?? []
        }

        this.links = response.data?.links ?? null
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },

    async getMyOrgTeam(pageURL, isPaginated) {
      this.loading = true
      let url = `/team`
      if (pageURL) {
        url = new URL(pageURL)
        url = url.toString().substring(url.origin.length)
        url = url.replace(/^(\/[\w-]+)*\/api\//, "/") // replace possible duplicate api path
      }
      let data = {
        my_org: "Y",
      }
      try {
        const response = await axios.get(url, { params: data })
        if (isPaginated) {
          this.myOrgTeams = [...this.myOrgTeams, ...(response.data?.data ?? [])]
        } else {
          this.myOrgTeams = response.data?.data ?? []
        }
        this.myOrgTeamsLink = response.data?.links ?? null
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },

    async searchTeamRequest() {
      this.loading = true
      const query = this.searchQuery

      try {
        const response = await axios.get("/team/search?team_name=" + query)
        if (response.data?.data.length) {
          this.searchedTeamRequest = response.data?.data ?? []
        } else {
          this.searchQuery = ""
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: this.$t("teams.team_not_found"),
          })
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    // All Teams Infinite Scroll Function
    ScrolledToBottom(isVisible) {
      if (!isVisible) {
        return
      }
      if (this.links.more_pages) {
        this.getAllTeam(this.links.next_page_url, true)
      }
    },
    // My Org Teams Infinite Scroll Function
    MyOrgScrolledToBottom(isVisible) {
      if (!isVisible) {
        return
      }
      if (this.myOrgTeamsLink.more_pages) {
        this.getMyOrgTeam(this.myOrgTeamsLink.next_page_url, true)
      }
    },
  },
  created() {
    this.getAllTeam()
    this.getMyOrgTeam()
  },
}
</script>

<style scoped>
.tabs > :first-child {
  border-bottom: 1px solid#dcdcdc;
}

.tabs >>> .nav-tabs {
  box-shadow: none !important;
}

.tabs >>> .nav-tabs .nav-link {
  color: #dcdcdc;
  background: transparent;
  box-shadow: none !important;
}
.tabs >>> .nav-tabs .nav-link.active {
  color: #4d4b8a;
  border-bottom: 2px solid #4d4b8a;
}

.tabs .tab-content .tab-pane {
  background: transparent !important;
  box-shadow: none !important;
}
#create-team {
  display: flex;
  justify-content: flex-end;
}
.input-field {
  padding-left: 20px;
}

@media (max-width: 991px) {
  #create-team {
    display: block;
    width: 100%;
    margin-top: 15px;
  }
  .input-field {
    padding-left: 0px;
  }
}
</style>
